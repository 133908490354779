<template>
    <div class="MovingBox">
        <ul class="T_down" v-for="(item,index) in myMailTree" :key="index">
            <tree-menu :treeData="item" :currentId="boxId" :isOpen="isOpen" :Types="Types" @boxidChange="boxidChange"></tree-menu>
        </ul>
    </div>
</template>
<script>
import TreeMenu from '../TreeMenu'
import { moveMailSuccess } from '../../../mailConst.js'
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
export default {
    name: 'MovingBox',
    title: '邮件移动',
    mixins: [titleMixin],
    data() {
        return {
            isOpen: true,
            myMailTree: [],
            mIds: [],
            boxId: -1,
            Types: 'mail',
            changeFlg: false,
            checkboxid: ''
        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    components: {
        'tree-menu': TreeMenu
    },
    created() {
    },
    mounted() {
        this.pageInit()
    },
    methods: {
        boxidChange(checkboxid) {
            this.checkboxid = checkboxid
        },
        setMenu() {
            let setMenu = this.Global.utils.rightMenu.setMenu('确定')
            setMenu.onClick(() => {
                this.treeItemClick()
            })
        },
        // 点击文件夹项item高亮
        async   treeItemClick() {
            if (this.checkboxid === '') {
                this.$toast.fail('请选择要移入的文件夹')
                return
            }
            // if (this.checkboxid === 'no') {
            //     this.$toast.fail('不允许移入该文件夹')
            //     return
            // }
            let boxId = this.checkboxid
            this.ishow = boxId
            let data = {}
            let url = ''
            if (this.Types == 'mail') {
                data = {
                    folder: boxId,
                    type: 'move',
                    mIds: this.mIds
                }
                url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailPut
            } else {
                data = {
                    ctid: this.companie.ctId,
                    action: 'remove',
                    boxId: this.currentId, // 原来的
                    targetBoxId: boxId
                }
                url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailsMailboxPut
            }

            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.Global.utils.setItem(moveMailSuccess, JSON.stringify({ date: (+new Date()), data: this.mIds }))
                    this.$toast.success('移动成功')
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 获取文件夹列表
        async  mailsMailboxesGet() {
            let params = {}
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailsMailboxesGet
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.myMailTree = [{
                        'boxPath': '',
                        'boxId': -2,
                        'boxName': '我的邮件',
                        'child': res.data.data
                    }]
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        pageInit() {
            if (this.$route.path == '/mail/MovingBox') {
                this.setMenu()
                // this.Global.utils.rightMenu.clearMenu()
                let { type, mIds, boxId } = this.$route.params
                this.Types = type
                this.mIds = mIds
                this.boxId = boxId
                this.checkboxid = ''
                this.mailsMailboxesGet()
            }
        }

    },
    watch: {
        $route() {
            this.pageInit()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
