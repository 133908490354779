<template>
    <li class="MenuLi" @click.stop="possibleChoose(Types,treeData.boxId,currentId,treeData.boxPath)">
        <h3>
            <i v-if="isFolder" @click.stop="toggle" class="iconfont " :class="[open ? 'icon-arrow-down': 'icon-arrow-right']"></i>
            <span class="label">
                <span class="labelColor">{{treeData.boxName}}</span>
            </span>
            <span class="T_set" v-if="blg">
                <i class="iconfont icon-hook"></i>
            </span>
        </h3>
        <ul class="T_down" v-show="open||isOpen" v-if="isFolder">
            <tree-menu v-for="(item,index) in treeData.child" :currentId="currentId" :treeData="item" :Types="Types" @boxidChange="boxidChange" :key="index"></tree-menu>
        </ul>
    </li>
</template>
<script>
export default {
    name: 'TreeMenu',
    props: ['treeData', 'Types', 'currentId', 'isOpen'],
    data() {
        return {
            open: false,
            blg: false,
            ishowId: '',
            checkboxid: ''
            // , radio: ''
        }
    },
    computed: {
        isFolder() {
            return this.treeData.child && this.treeData.child.length
        }

    },
    created() {
    },
    mounted() {
    },

    methods: {
        boxidChange(boxId) {
            this.$emit('boxidChange', boxId)
        },
        // 展开子菜单
        toggle: function () {
            if (this.isFolder) {
                this.open = !this.open
            }
        },
        // 是否可以选择
        possibleChoose(type, boxid, crtId, boxPath) {
            this.blg = true
            if (type == 'folder') { // 文件夹  文件夹不可以移动 0\3\4\5\6 \(本身以及本文件夹的子文件夹)
                if (boxid == 0 || boxid == 3 || boxid == 4 || boxid == 5 || boxid == 6 || boxid == parseInt(crtId)) {
                    this.blg = false
                } else {
                    let arrys = boxPath.toString().split('/')
                    for (let i = 0; i < arrys.length; i++) {
                        if (arrys[i] == parseInt(crtId)) {
                            this.blg = false
                            break
                        }
                    }
                }
            } else { // 邮件 邮件不可以移动到  3\5 (我的邮件下面) (本身所在的文件夹)
                if (boxid == 3 || boxid == 5 || boxid == -1 || boxid == parseInt(crtId)) {
                    this.blg = false
                }
            }
            if (this.blg) {
                this.boxidChange(boxid)
            } else {
                this.boxidChange('')
                this.$toast.fail('不允许移入该文件夹')
            }
            return this.blg
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
